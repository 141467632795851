.mainTable {
  width: 93%;
  padding-bottom: 3%;
  margin-left: 3%;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  background: #ffffff;
}
.mainTable > header {
  display: none;
}

button.print {
  display: none;
}
.mainTableHeader {
  display: flex;
}

.data-table-extensions-filter {
  float: right;
  padding-top: 23px;
  padding-bottom: 20px;
  padding-right: 25px;
}
.data-table-extensions-filter > input {
  width: 350px;
  height: 30px;
  /* border-radius: 25px; */
}

.mainTableHeader1 {
  float: left;
  padding-top: 25px;
  padding-left: 30px;
  font-size: 22px;
  font-weight: 600;
}

.mainTableHeader2 {
  float: right;
  padding-top: 30px;
  padding-right: 50px;
}

.mainTableHeader3 {
  float: right;
  padding-top: 30px;
  padding-right: 70px;
}

.rdt_TableCol {
  color: black;
  font-weight: bolder;
  font-size: 14px;
  background-color: #f7fbfe;
}

.filter-text {
  position: absolute;
  max-width: 246px;
  top: 130px;
  right: 613px;
  height: 34px !important ;
  border-radius: 7px;
  border: 1.2px solid black;
  padding-left: 2px;
}
