.geeks {
    width: 300px;
    height: 300px;
    overflow: hidden;
    margin: 0 auto;
}

.geeks img {
    width: 100%;
    transition: 0.1s ease-out 0s;
}

.geeks:hover img {
    transform: scale(1.5);
    transform-origin: auto auto;
}